import React from "react";
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import {Helmet} from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image"

import "../scss/main.scss";

/// Layout
import Header from "../jsx/layouts/nav/Header";
import Footer from "../jsx/layouts/Footer";

import StateSVG from "../vendor/svgs/state-svg-defs.svg";

export const query = graphql`
  query($slug: String!, $bioguide_imgs: [String!]!, $bioguides: [String!]!, $state: String!) {
    statesJson(fields: { slug: { eq: $slug } }) {
    	state_name
    	state
    	people {
    		bioguide
    		name
    		position
    		party_code
    		current_term
    		twitter
    		slug
        contact
    	}
    }
    allImageSharp(filter: {fixed: {originalName: {in: $bioguide_imgs}}}) {
      edges {
        node {
          fixed {
            originalName
          }
          gatsbyImageData(width: 90)
        }
      }
    }
    stateScoresJson(name: {eq: $state}) {
      abc_score
      name
      num_neg
      num_pos
      pct_pos
    }
    allPeopleScoresJson(filter: {bioguide: {in: $bioguides}}) {
      edges {
        node {
          abc_score
          bioguide
          name
          num_neg
          num_pos
          pct_pos
          state
        }
      }
    }
  }
`

function get_grade_class(grade) {
  return grade == 'NR' ? 'grade-nr text-center m-auto'
       : grade == 'F'  ? 'grade-f text-center m-auto'
       : grade == 'D'  ? 'grade-d text-center m-auto'
       : grade == 'C'  ? 'grade-c text-center m-auto'
       : grade == 'C+' ? 'grade-cp text-center m-auto'
       : grade == 'B-' ? 'grade-bm text-center m-auto'
       : grade == 'B'  ? 'grade-b text-center m-auto'
       : grade == 'B+' ? 'grade-bp text-center m-auto'
       : grade == 'A-' ? 'grade-am text-center m-auto'
       : grade == 'A'  ? 'grade-a text-center m-auto'
       : grade == 'A+' ? 'grade-ap text-center m-auto'
       : 'grade-nr text-center m-auto';
}

export default function Home({ data }) {
  // build out all of the data transformations we will need on the page
  // var last_term = data.statesJson.terms.length - 1
  data['custom'] = {}
  data['custom']['state_svg_className1'] = 'state-icon icon-state-' + data.statesJson.state + ' ml-n4'
  data['custom']['state_svg_className2'] = '#icon-state-' + data.statesJson.state

  if (data.stateScoresJson.abc_score == 'NR') {
    data['custom']['state_score_class'] = "fs-32 font-w500 d-block text-center text-dark state-grade-nr text-center m-auto"
  } else {
    data['custom']['state_score_class'] = "fs-32 font-w500 d-block text-center text-dark " + get_grade_class(data.stateScoresJson.abc_score)
  }
  
  data['custom']['state_progress_bar'] = {}
  data['custom']['state_progress_bar']['pos_pct'] = ((data.stateScoresJson.pct_pos) * 100) + '%'
  data['custom']['state_progress_bar']['neg_pct'] = ((data.stateScoresJson.pct_pos > 0) ? (1.0 - data.stateScoresJson.pct_pos) * 100 + '%' : ((data.stateScoresJson.num_neg + data.stateScoresJson.num_pos) > 0 ? '100%' : '0%'))

  // add our image data from GraphQL to each 'people' object
  for (let i = 0; i < data.statesJson.people.length; i++) {
  	data.statesJson.people[i]['profile_image'] = data.allImageSharp.edges.filter(edge => edge.node.fixed.originalName == data.statesJson.people[i].bioguide +'.jpg')
    data.statesJson.people[i]['abc_score'] = data.allPeopleScoresJson.edges.filter(edge => edge.node.bioguide == data.statesJson.people[i].bioguide)
    data.statesJson.people[i]['person_score_class'] = "fs-32 font-w500 d-block text-center text-white " + get_grade_class(data.statesJson.people[i]['abc_score'][0].node.abc_score)
  }

  const us_territories = ['AS', 'DC', 'GU', 'MP', 'PR', 'VI', 'DK', 'OL', 'PI']

  // console.log(data)
  return (
    <>
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width,initial-scale=1"/>
        <script defer data-domain="electbitcoin.org" src="https://data.electbitcoin.org/js/script.js"></script>
        {us_territories.includes(data.statesJson.state) &&
          <title>ElectBitcoin.org - {data.statesJson.state_name}</title>
        }
        {!us_territories.includes(data.statesJson.state) &&
          <title>ElectBitcoin.org - The State of {data.statesJson.state_name}</title>
        }
      </Helmet>
		  <StateSVG />
      <Header />
      <div className="content-body">
        <div className="container-fluid pt-2">
          <div class="text-center pb-2">
            <a href="https://twitter.com/ElectBitcoinOrg" target="_blank" class="badge badge-info badge-rounded">
              <i class="lab la-twitter scale3"></i> Follow @ElectBitcoinOrg for updates when new records are added!
            </a>
          </div>
          <div className="row">
          	<div className="col-xl-12">
          		<div className="card bg-dark text-white">
                <div className="card-body">
                  <div className="media d-md-flex d-block align-items-center">
                    <span className="col-xl-3 col-xxl-3 col-lg-4 col-md-5 col-sm-12 d-block mb-sm-0 mb-3">
                      <svg className={data.custom.state_svg_className1}><use xlinkHref={data.custom.state_svg_className2}></use></svg>
                    </span>
                    <div className="media-body col-xl-7 col-xxl-7 col-lg-6 col-md-5 col-sm-12 mb-sm-0 mb-3 pl-0">
                      <h4 className="fs-36 text-white">{data.statesJson.state_name}</h4>
                      <div className="progress mt-3 mb-3" style={{ height: 10 }}>
                        <div
                          className="progress-bar progress-bar-success progress-bar-striped progress-animated"
                          style={{ width: `${data.custom.state_progress_bar.pos_pct}`, height: 10 }}
                          role="progressbar"
                        >
                        </div>
                        <div
                          className="progress-bar progress-bar-danger progress-bar-striped progress-animated"
                          style={{ width: `${data.custom.state_progress_bar.neg_pct}`, height: 10 }}
                          role="progressbar"
                        >
                        </div>
                      </div>
                      <div className="d-inline">
                        <span className="text-success text-center mr-4">{data.stateScoresJson.num_pos} Positive <i className="las la-thumbs-up scale5"></i></span>
                      	<span className="text-danger text-center float-right">{data.stateScoresJson.num_neg} Negative <i className="las la-thumbs-down scale5"></i></span>
                      </div>
                    </div>
                    <div className="d-block col-xl-2 col-xxl-2 col-lg-2 col-md-2 col-sm-12 p-0">
                    	<span className="fs-26 text-white font-w500 d-block text-center">State Average: </span>
                    	<span className={data.custom.state_score_class}>{data.stateScoresJson.abc_score}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
		        <div className="col-xl-12">
		          <div className="card">
		            <div className="card-body p-0">
                	{data.statesJson.people.map((people) => (
                		<div className="row border-bottom mx-0 pt-4 px-2 align-items-center" key={people.slug + '/'}>
	                		<div className="col-xl-4 col-xxl-4 col-lg-5 col-sm-8 mb-sm-4 mb-3 align-items-center media">
			                	<Link to={people.slug + '/'}>
			                		<GatsbyImage image={people.profile_image[0].node.gatsbyImageData} alt={people.name} className="mr-sm-4 mr-3 img-fluid rounded" width={90}/>
				                </Link>
			                  <div className="media-body">
			                  	<Link to={people.slug + '/'}>
				                    <span className="text-primary d-block">{people.position}</span>
				                    <h3 className="fs-20 text-black font-w600 mb-1">
				                      {people.name} ({people.party_code})
				                    </h3>
				                  </Link>
			                  </div>
			                </div>
			                <div className="col-xl-3 col-xxl-3 col-lg-4 d-none d-lg-block mb-sm-4 col-6 mb-3">
			                  <small className="mb-2 d-block">Current Term</small>
			                  <span className="text-black font-w600">
			                    {people.current_term}
			                  </span>
			                </div>
			                <div className="col-xl-3 col-xxl-3 d-none d-xl-block mb-sm-4 col-6 mb-3 text-center">
                        {people.twitter != null &&
			                    <a href={'https://twitter.com/' + people.twitter} target="_blank"><button type="button" className="btn btn-twitter rounded">@{people.twitter} <span className="btn-icon-right"><i className="fab fa-twitter fa-lg"></i></span></button></a>
                        }
                        {people.twitter == null && people.contact != null &&
			                    <a href={people.contact} target="_blank"><button type="button" className="btn btn-primary rounded"><i className="las la-envelope-open scale5 mr-2"></i>Email {people.name}</button></a>
                        }
			                </div>
			                <div className="col-xl-2 col-xxl-2 col-lg-3 col-sm-4 mb-sm-4 mb-3 align-items-center d-block media text-center">
			                	<span className="h4 text-dark d-block text-center">ElectBitcoin Grade: </span>
	                    	<span className={people.person_score_class}>{people.abc_score[0].node.abc_score}</span>
			                </div>
			              </div>
			            ))}
		            </div>
		          </div>
		        </div>
		      </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
